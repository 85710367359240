header {
  .navbar-brand {
    img {
      width: 100%;
      max-width: 290px;
      height: 48px;
    }
  }

  li.nav-item {
    padding: 0 25px;
    .nav-link {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.55);
    }
  }
  .register-btn {
    background: #0010f6;
    color: #fff;
    font-weight: 500;
    padding: 7px 20px;
    margin-left: 15px;
  }
}

@media only screen and (max-width: 1200px) {
  header li.nav-item {
    padding: 0 10px;
  }
}

@media screen and (max-width: 991px) {
  header .register-btn {
    background: #fff;
    color: rgba(0, 0, 0, 0.55);
    margin-left: 0;
    padding: 0;
  }

  .login-register ul li {
    padding: 0 10px;
  }
}
@media only screen and (max-width: 767px) {
  header .navbar-brand img {
    width: 100%;
    max-width: 200px;
    height: 36px;
  }
}
