.elevated-div {
  background-color: #f7fafc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease;
  border-radius: 8px;
  padding: 15px;
}

.elevated-div:hover {
  transform: scale(1.01);
}
