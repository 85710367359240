.slider {
  img {
    width: 100%;
  }
}
.kt-platform {
  background: #eceef4;
  padding: 30px 0;
  .title {
    position: relative;
    h2 {
      margin-bottom: 0;
      font-size: 28px;
      font-weight: 600;
      color: #000;
      &:after {
        position: absolute;
        bottom: -12px;
        left: 0;
        right: 0;
        display: block;
        margin: 0 auto;
        width: 100px;
        height: 2px;
        background-color: #0010f6;
        content: "";
      }
    }
  }
  .content-text {
    width: 70%;
    .register-btn {
      background: #0010f6;
      color: #fff;
      font-weight: 500;
      padding: 7px 20px;
    }
    h4 {
      font-size: 22px;
      font-weight: 600;
    }
    p {
      color: #0000004d;
      font-size: 14px;
    }
  }
}

.bg-tranh {
  background-color: transparent;
  margin-top: 50px;
}
.slider video {
  width: 100%;
}
.kt-platform .content-text.right-img {
  width: 100%;
}
.right-img img,
.right-img svg {
  width: 100%;
  height: 600px;
  object-fit: cover;
}
.heart-people .right-img img {
  height: 100%;
}
.bgtwo .right-img img,
.bgtwo .right-img svg {
  max-width: 500px;
  height: 100%;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: optimizeQuality;
}

.bg-tranh {
  ul {
    padding: 0;
    margin-top: 35px;
    li {
      display: flex;
      list-style: none;
      align-items: center;
      margin-bottom: 15px;
      span {
        background: #d1f8f8;
        width: 35px;
        height: 35px;
        display: inline-block;
        border-radius: 50%;
        text-align: center;
        line-height: 35px;
      }
      p {
        margin-bottom: 0;
        margin-left: 10px;
      }
    }
  }
}

i.fa.fa-heart {
  color: #0010f6;
}
// .kt-platform.bg-gray {
//     background: #f7f7f7;
// }
.innovation li {
  border: 1px solid #777777a1;
  margin-bottom: 15px;
  display: flex;
  padding: 10px;
  width: 55%;
  border-radius: 10px;
  align-items: center;
}
.innovation li span {
  margin-right: 10px;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 20px;
  min-width: 40px;
  background-color: #fde7e9;
}
.innovation li p {
  margin: 0px;
}
ul.innovation {
  padding: 0;
}
.innovation i.fa.fa-heart {
  color: #fe0922;
}
.innovation .green span {
  background: #eafff9;
}
.innovation .green span i.fa.fa-heart {
  color: #02f7be;
}
.slider {
  box-shadow: 0px 5px 15px #00000057;
}
.bgone {
  background-image: url("../../assets/bg2.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.bgtwo {
  background-image: url("../../assets/bg3.svg");
}

@media only screen and (max-width: 767px) {
  .innovation li {
    width: 100%;
  }
  .right-img img,
  .right-img svg {
    height: 370px;
  }
}

@media (min-width: 1400px) {
  .banner-img {
    max-width: 1400px;
    margin: auto;
  }
}

@media (min-width: 1200px) {
  .text-container {
    justify-content: center;
    text-align: center;

    .col-md-6 {
      padding-left: 10%;

      .content-text {
        width: 90%;
      }
    }
  }
}

.unmute-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: red;
  color: #fff;
  border: none;
}

.w-50 {
  width: 50%;
}

.table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid gray;
}

/* Style table rows */
.table tr {
  border-bottom: 1px solid gray;
  border-top: 1px solid gray;
}

/* Style table cells */
.table td {
  padding: 20px;
}

.left-border {
  border-left: 1px solid gray;
}

.gray--text {
  color: #4a5568;
}

.subheader {
  font-weight: 600;
  color: #4a5568;
}

.kt-list,
.kt-list-ul {
  list-style: none;
  padding: 0;
  margin: 0;
  color: #4a5568;
}

/* Style list items */
.kt-list li {
  padding: 10px;
  border-radius: 5px;
  background-color: #f7fafc;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

/* Hover effect */
.kt-list li:hover {
  background-color: #edf2f7;
  transform: scale(1.01);
}

.transparent-bg {
  background-color: transparent;
}

.hover-effect {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

/* Style the elements inside */
.hover-effect p,
.hover-effect button,
.hover-effect span {
  font-size: 20px;
  color: #333;
  transition: transform 0.3s ease, color 0.3s ease;
}

/* Hover effect */
.hover-effect p:hover,
.hover-effect button:hover,
.hover-effect span:hover {
  transform: scale(1.2);
  color: #ff6f00;
}

.social-icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 150px;
}

.icon {
  font-size: 24px;
  transition: color 0.3s ease;
  cursor: pointer;
}

.icon:hover {
  color: #00a0d2; /* Customize the hover color */
}

.content-display {
  display: contents;
}
