@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
body {
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif !important;
}
.slider.border-no {
  border: none;
  box-shadow: none;
}
div#image-overlay,
div#image-overlay {
  display: none !important;
  box-shadow: none !important;
}
div.image-overlay-line {
  transform: none !important;
  display: none !important;
}
.subheader-color {
  color: #4a5568;
}
.header-color {
  color: #1a202c;
}

.expand {
  transition: transform 0.4s ease;
}

.expand:hover {
  transform: scale(1.01);
}

.icon-expand {
  transition: transform 0.4s ease;
}

.icon-expand:hover {
  transform: scale(1.1);
}

.cursor-pointer {
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #1a202c;
}

p {
  color: #4a5568;
}
