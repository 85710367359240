.banner.banner-price{
  text-align: center;
  background-image: url("../../assets/bg1.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 40px;
}
.form-check.form-switch input {
  float: right;
}
.pricetabletogglebtn {
  display: flex;
  justify-content: flex-end;
}

label.form-check-label {
  margin-right: 44px;
}
.pricetable h2 {
  font-size: 28px;
  font-weight: 500;
  color: #0d6efd;
  margin: 0;
}
.pricetable p {
  font-size: 18px;
  color: #0d6efd;
}
.ul-plans h2 {
  font-weight: 600;
  font-size: 20px;
  color: #0d6efd;
  padding-top: 20px;
  text-align: center;
  margin: 0;
  padding-bottom: 0px;
}
.ul-plans h2 span {
  color: #fff;
}
.planservice {
  background: #eb866c;
  border-radius: 10px;
  text-align: center;
}
.planservice li {
  border-top: 1px solid #0d6efd;
  padding: 10px 20px;
  font-size: 12px;
  color: #fff;
  height: 48px;
}
.planservice.w-cust {
  margin-top: -31px;
}
ul.ul-plans {
  padding: 0;
}
.planservice h3 {
  font-size: 16px;
  font-weight: 600;
  padding: 20px 10px 10px;
  color: #0d6efd;
}
.planservice > img {
  width: 100%;
  max-width: 150px;
  margin: 0 auto;
}
ul.ul-plans {
  text-align: center;
  list-style: none;
  margin-bottom: 0;
}
.ul-plans li img {
  width: 100%;
  max-width: 30px;
}
.planservice.bg-white {
  border: 1px solid;
  width: 356px;
}

ul.ul-plans.oneul {
  text-align: left;
}
.planservice p {
  margin: 3px 0;
  padding: 0 2px;
  font-size: 16px;
}
.planservice.bg-white.bg-blue {
  background: #0010f6 !important;
}
.planservice.bg-white.bg-blue h3 {
  color: #fff;
}
.planservice.bg-white.bg-blue p {
  color: #fff;
}
.w-cust {
  width: 510px;
}
li.select-plan button {
    background: #bbc2e8;
    border: none;
    padding: 10px 25px;
    border-radius: 15px 15px 0 0;
    color: #fff;
    font-weight: 600;
}
li.select-plan {
    padding-bottom: 0;
    padding-top: 20px;
    height: 58px;
}

ul.ul-plans.oneul li {
  display: flex;
  align-items: center;
}

p.new-text {
  font-size: 14px;
}
button.button-rate {
  background: #9e6aaf;
  border: none;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  padding: 0 20px;
  height: auto;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  width: 80%;
}
.button-rate span {
  font-size: 12px;
  margin-top: 3px;
}
.styled-list li{
  padding: 5px 0;
}


// @media only screen and (min-width: 1400px) {
//   .banner.banner-price{
//     text-align: center;
//     background-image: url("../../assets/bg1.svg");
//     background-repeat: no-repeat;
//     background-position: center;
//     background-size: cover;
//     padding: 40px;
//   }
//   .banner img{
//     width: 100%;
//     max-width: 80%;
//     margin: 0 auto;
//   }
// }

@media only screen and (max-width: 1200px) {
  .width-auto {
    flex-wrap: wrap;
  }
  .planservice {
    width: 33% !important;
    margin-bottom: 40px;
  }
  .planservice li {
    height: auto;
  }
 
}

@media only screen and (max-width: 767px) {
  .planservice {
    width: 49% !important;
  }
  .planservice.w-cust {
    margin-top: 0;
  }
}

@media only screen and (max-width: 480px) {
  .planservice {
    width: 100% !important;
  }
}

.styled-list {
  list-style-image: url('../../assets/check-mark.png');
}

.mb-2rem { margin-bottom: 2rem; }