.bg-tranh {
  background-color: transparent;
}
.content-text h6 {
  font-size: 18px;
  line-height: 28px;
}
.content-text.technology-div {
  margin: 0 auto;
}
.register-btn {
  background: #0010f6;
  color: #fff;
  font-weight: 500;
  padding: 7px 20px;
}
.logo-images ul li {
  width: 16%;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px 15px;
}
.logo-images ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.logo-images ul li img {
  width: 100%;
  max-width: 130px;
}
.forms {
  border: 3px solid;
  padding: 40px;
  width: 100%;
  max-width: 70%;
  margin: 0 auto;
}
.kt-platform .forms h2 {
  text-align: left;
  font-size: 24px;
}
.kt-platform .forms .title h2:after {
  right: inherit;
}
.kt-platform .forms .title {
  margin-bottom: 50px;
}
.customwidth {
  display: flex;
  justify-content: space-between;
}
.form-group input {
  width: 100%;
  height: 42px;
  border: 1px solid gray;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 6px;
  color: gray;
}
.form-group {
  width: 100%;
}
.customwidth {
  display: flex;
  justify-content: space-between;
}
.customwidth .form-group {
  width: 48%;
}

.banner.techno {
  text-align: center;
  background-image: url("../../assets/bg2.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 40px;
}

@media only screen and (max-width: 1200px) {
  .kt-platform .content-text {
    width: 100%;
  }
  .forms {
    padding: 15px;
    width: 100%;
    max-width: 100%;
  }
  .kt-platform .col-md-12.text-center.mb-5.pb-4 {
    margin-bottom: 0 !important;
  }
}

@media only screen and (max-width: 767px) {
  .logo-images ul li {
    width: 34%;
  }
  .kt-platform .title h2 {
    font-size: 20px;
  }
  .content-text h6 {
    font-size: 16px;
  }
  .kt-platform .content-text h4 {
    font-size: 18px;
    line-height: 25px;
  }

  section.kt-platform.bg-tranh {
    padding-top: 0 !important;
  }
  .kt-platform.bg-tranh .col-md-6.text-start.mb-5.pb-4 {
    margin-bottom: 0 !important;
  }
}
