.banner{
  text-align: center;
  background-image: url("../../assets/bg3.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 40px;
}
.bg-tranh {
  background-color: transparent;
}
.support-bg {
  background-color: transparent;
  background-image: url("../../assets/bg1.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

section.kt-platform.support-bg img.w-75,
.support-bgnew img.w-75 {
  width: 100% !important;
  height: 100%;
}

// @media only screen and (min-width: 1400px) {
//   .banner{
//     text-align: center;
//     background-image: url("../../assets/bg3.svg");
//     background-repeat: no-repeat;
//     background-position: center;
//     background-size: cover;
//     padding: 40px;
//   }
//   .banner img{
//     width: 100%;
//     max-width: 80%;
//     margin: 0 auto;
//   }
// }

@media only screen and (max-width: 1200px) {
  section.kt-platform.support-bg img.w-75,
  .support-bgnew img.w-75 {
    height: 290px;
  }
}
@media only screen and (max-width: 767px) {
  section.kt-platform.support-bg img.w-75,
  .support-bgnew img.w-75 {
    height: 220px;
  }
}
