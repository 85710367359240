footer {
  margin-top: 40px;

  img {
    width: 100%;
    max-width: 290px;
    height: 48px;
  }

  .social-media {
    ul {
      padding: 0;
      margin: 5px 0 10px;
      display: flex;
      list-style: none;
      align-items: center;
      justify-content: center;

      li {
        margin: 0 10px;

        i {
          width: 30px;
          height: 30px;
          line-height: 30px;
          color: #fff;
          display: block;
          border-radius: 50%;
        }

        i.fab.fa-facebook-f {
          background: #4267b2;
        }

        i.fab.fa-linkedin-in {
          background: #0072b1;
        }

        i.fab.fa-twitter {
          background: #1da1f2;
        }

        i.fab.fa-youtube {
          background: #ff0000;
        }
      }
    }
    p {
      margin-top: 15px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.55);
    }
  }
}

.policy-links {
  text-decoration: none;
  font-size: 14px;
  color: #718096;
}
